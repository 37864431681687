/*BODY*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');
*{
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
}
#teste {
    filter: brightness(75%);
}
.carousel-item {
    display: flex;
    align-items: center;
    justify-content: center;
}
.carousel-caption {
    bottom: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.carousel-caption h1 {
    color: #fff;
    font-size: 3rem;
    font-weight: 700;
}
.carousel-caption p {
    font-size: 24px;
    color: #fff;
}
section .btn {
    width: 180px;
    height: 50px;
    background: #EA242E;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 1rem;
    font-weight: 700;
    border-radius: 50px;

    color: #fff;
}
section .btn:hover {
    color: #fff;
}
section .card-body #section-btn{
    display: flex;
    flex-direction: column;
    align-items: center;
}
section .card-body h5 {
    color: #363636;
}
section .card-body h4 {
    display: flex;
    align-items: center;
    justify-items: center;
    font-size: 1rem;
    color: #4F4F4F;
}
section .card-body h4 i {
    font-size: 24px;
    margin-right: 8px;
    color: #EA242E;
}

.content {
    width: 90%;
    margin-right: auto;
}
.content h2 {
    color: #363636;
    font-size: 2rem;
}
.content h3 {
    font-size: 1.5rem;
    font-weight: 400;
    color: #A9A9A9;
}
.content .w-100 {
    border-radius: 4px;
}
#content-participantes #images {
    display: flex;
    width: 100%;
}
#content-participantes #images .col-md-6 {
    display: inline;
}
#content-participantes #images h4 {
    font-size: 1rem;
    color: #4F4F4F;
}
#content-participantes h3 {
    font-size: 1.5rem;
    color: #4F4F4F;
}
#content-participantes #images img {
    width: 100%;
    border-radius: 4px;
}
.content .section-info {
    display: flex;
    margin-top: 24px;
}
.content .section-info p {
    width: 60%;
    color: #4F4F4F;
}
.content .section-info h4 {
    display: flex;
    align-items: center;
    justify-items: center;
    font-size: 1.2rem;
    color: #4F4F4F;
}
.content .section-info h4 i {
    font-size: 24px;
    margin-right: 16px;
    color: #EA242E;
}
.content hr {
    margin-top: 8px;
    margin-bottom: 8px;
    border: 0;
    border-top: 2px solid #eee;
    box-sizing: content-box;
    height: 0;
}
.img {
    width: 100%;
}
#content-participantes #images #mobile{
    display: none;
}
@media (max-width:1400px) {
    #carouselHeader {
        margin-top: 72px;
    }
    .carousel-caption {
        bottom: 20%;
    }
    .carousel-caption h1 {
        font-size: 2.5rem;
    }
}
@media (max-width:1200px) {
    .carousel-caption h1 {
        font-size: 2rem;
    }
    .carousel-caption p {
        font-size: 1.2rem;
    }
    #content-participantes #images .row img {
        width: 80%;
        margin-bottom: 16px;
    }
    #fotos {
        margin-top: 16px;
    }
    .card {
        margin-top: 16px;
    }
}
@media (min-width: 576px){
    .modal-dialog {
        max-width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
@media (max-width:700px) {
    .carousel-caption {
        bottom: 32px;
    }
    .carousel-caption h1 {
        font-size: 1.5rem;
    }
    .carousel-caption p {
        display: none;
    }
}
@media (max-width:533px) {
    #carouselHeader {
        margin-top: 72px;
    }
    .content {
        width: 100%;
        margin-right: auto;
    }
    .carousel-caption {
        bottom: -15px;
        margin-top: 76px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .carousel-caption p {
        display: none;
    }
    .carousel-caption h1 {
        margin-top: 24px;
        font-size: 1.5rem;
    }
    #content-participantes #images {
        margin-bottom: 32px;
    }
    #content-participantes #images #mobile{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    #content-participantes #images #mobile .content img {
        width: 90%;
    }
    #content-participantes #images .row img {
        display: none;
    }
    #content-participantes #images .row h4 {
        display: none;
    }
    .content .section-info {
        display: flex;
        flex-direction: column;
        margin-top: 16px;
        margin-bottom: 16px;
    }
    .content .section-info p {
        width: 100%;
    }
    #fotos {
        margin-top: 16px;
    }
    .card {
        width: 100%;
        margin-top: 16px;
    }
    .content h2 {
        color: #363636;
        font-size: 1.5rem;
    }
    .content h3 {
        font-size: 1rem;
        font-weight: 400;
        color: #A9A9A9;
    }
    #content-participantes h3 {
        font-size: 1.2rem;
        font-weight: 600;
        color: #4F4F4F;
    }
}
@media (max-width:475px) {
    .carousel-caption h1 {
        font-size: 1.2rem;
    }
}
@media (max-width:420px) {
    .carousel-caption h1 {
        display: none;
    }
}
/*FLOAT*/
.fixed-action-btn {
    position: fixed;
    right: 23px;
    bottom: 23px;
    padding-top: 15px;
    margin-bottom: 0;
    z-index: 997;
    bottom: 24px;
    right: 24px;
}
@media (max-width:533px) {
    .fixed-action-btn {
        bottom: 16px;
        right: 12px;
    }
}

/*NAVBAR*/

.navbar img {
    height: 50px;
    margin-right: 24px;
}
.navbar .social {
    display: flex;
}
.navbar .social .icon img
{
    width: 40px;
    height: 40px;
    margin: 0 8px;
    color: #FFF;
}
#btn-player {
    width: 250px;
    height: 76px;
    background: #FBD410;

    display: flex;
    align-items: center;
    padding: 6px 8px 6px 8px;
    border-radius: 50px;
    margin-top: 24px;

    font-size: 1rem;
    font-weight: 700;

    box-shadow: 0px 4px 20px 5px rgba(72, 51, 51, 0.25);
    color: #fff;
    transition: 0.5s;
}
#btn-player:hover {
    background: #D8B716;
    color: #fff;
}
.btn i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    font-size: 48px;
    margin-right: 8px;
}
.bg-active{
    background-color: transparent !important;
    color: #EA242E !important;
    border: 2px solid #EA242E !important;
    font-size: 1rem;
}
.link{
    color: #545454;
    font-weight: 700;
    text-decoration: none;
    transition: 0.3s;
}
.link:hover {
    color: #EA242E;
}
@media (max-width:533px) {
    #btn-player {
        width: 250px;
        height: 66px;
        background: #FBD410;

        display: flex;
        align-items: center;
        padding: 6px 8px 6px 8px;
        border-radius: 50px;
        margin-top: 8px;
        margin-bottom: 24px;

        font-size: 1rem;
        font-weight: 700;

        box-shadow: 0px 4px 20px 5px rgba(72, 51, 51, 0.25);
        color: #fff;
        transition: 0.5s;
    }
    #btn-player:hover {
        background: #D8B716;
        color: #fff;
    }
    .btn i {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        font-size: 38px;
        margin-right: 8px;
    }
}

/*FOOTER*/
footer
{
    box-shadow: 0 -4px 16px rgb(0 0 0 / 8%);
    background: #FF2F44;
    height: auto;
}
footer .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 40px;
    margin: 24px auto;
}
footer #copyright {
    background: #363636;
    color: #fff;

    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
footer #copyright h5 {
    font-size: 0.8rem;
}
footer #logo {
    margin-right: 48px;
}
footer .container #logo-img {
    width: 150px;
}
.section-contato h5 {
    color: #fff;
}
.section-contato h5 i {
    margin-right: 16px;
}
footer #copyright {
    color: #fff;
}
@media (max-width:533px) {
    .section-contato h5 {
        font-size: 1rem;
    }
    footer .container {
        justify-content: center;
    }
    footer #copyright h5 {
        font-size: 0.8rem;
    }
}

/*CONTACT*/
.contact .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.contact .content p {
    width: 75%;
    text-align: center;
}
.container-contact {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
}
.contact .container .contactInfo
{
    width: 50%;
    display: flex;
    flex-direction: column;
}
.contact .container .contactInfo .box
{
    position: relative;
    padding: 20px 0;
    display: flex;
}
.contact .container .contactInfo .box .icon
{
    min-width: 60px;
    height: 60px;
    background:#EA242E;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 22px;
}
.contact .container .contactInfo .box .text
{
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    font-size: 16px;
    color: #808080;
    font-weight: 500;
}
.contact .container .contactInfo .box .text h3
{
    font-weight: 600;
    color: #363636;
}
.contact .container .contactInfo i {
    color: #fff;
}
.contactForm
{
    width: 40%;
    padding: 40px;
    background: rgba(255, 255, 255, 0.9);

    border-radius: 5px;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.2);

}
.contactForm h2
{
    font-size: 30px;
    color: #333;
    font-weight: 500;
}
.contactForm .inputBox
{
    position: relative;
    width: 100%;
    margin-top: 10px;
}
.contactForm .inputBox input, textarea
{
    width: 100%;
    padding: 5px 0;
    font-size: 1rem;
    margin: 10px 0;
    border: none;
    background: rgba(255, 255, 255, 0);
    border-bottom: 2px solid #333;
    outline: none;
    resize: none;
}
.contactForm .inputBox span
{
    position: absolute;
    left: 0;
    padding: 5px 0;
    font-size: 1rem;
    margin: 10px 0;
    pointer-events: none;
    transition: 0.5s;
    color: #666;
}
.contactForm .inputBox input:focus ~ span,
.contactForm .inputBox input:valid ~ span,
.contactForm .inputBox textarea:focus ~ span,
.contactForm .inputBox textarea:valid ~ span
{
    color: #EA242E;
    font-size: 12px;
    transform: translateY(-20px);
}
.contactForm .inputBox input[type="submit"]
{
    width: 200px;
    height: 58px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: #EA242E;
    color: #FAF5F2;

    font-size: 1rem;
    font-weight: 600;
}

@media (max-width:1000px) {
    .container-contact {
        margin-top: 24px;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .contactForm
    {
        width: 70%;
        padding: 40px;
        background: rgba(255, 255, 255, 0.9);

        border-radius: 5px;
        box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.2);

    }
    .contact .container .contactInfo {
        width: 80%;
        display: flex;
        flex-direction: column;
    }
}
@media (max-width: 740px){
    .contactForm
    {
        width: 80%;
        padding: 40px;
        background: rgba(255, 255, 255, 0.9);

        border-radius: 5px;
        box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.2);

    }
}
@media (max-width: 576px){
    .contact .container .contactInfo {
        width: 90%;
        display: flex;
        flex-direction: column;
    }
    .contactForm
    {
        width: 90%;
        padding: 40px;
        background: rgba(255, 255, 255, 0.9);

        border-radius: 5px;
        box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.2);

    }
}